<template>
  <div class="my">
    <header-bar :title="$t('base_info_personal_info')" :hideBack="true" color="#ffffff"/>
    <div class="avatar-div">
      <span class="mobile">{{ $t('account').toUpperCase() }}:&nbsp;{{ user.mobile }}</span>
      <img :src="scoreImg" class="avatar">
      <span class="score-value">{{ user.creditScore }}</span>
      <span class="score-desc">{{ $t('credit_score').toUpperCase() }}</span>
    </div>
    <div class="menu">
      <div class="menu-item gray" @click="goPage('/baseInfoDetail')">{{ $t('base_info_title') }}</div>
      <div class="menu-item gray" @click="recharge">{{ $t('recharge') }}</div>
      <div class="menu-item green" @click="store.contactUs" v-if="store.showContact">
        {{ $t('contact_customer_service') }}
      </div>
    </div>
    <div class="menu">
      <div class="menu-item red" @click="logout">
        {{ $t('mine_logout') }}
      </div>
    </div>
    <bottom-bar current-name="My"></bottom-bar>
    <van-popup v-model="showRechargeAmt" :close-on-click-overlay=false class="recharge-div">
      <van-field v-model.number="rechargeParams.amt" clearable :placeholder="$t('input_recharge_amount')" type="number"
                 input-align="left" :required="true"/>
      <div class="confirm-recharge">
        <button class="cancel" @click="showRechargeAmt=false">{{ $t('common_cancel') }}</button>
        <button class="confirm" :class="{disabled:!rechargeParams.amt}"
                :disabled='!rechargeParams.amt' @click="confirmRecharge">{{ $t('common_confirm') }}
        </button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import scoreImg from '@/assets/credit-score.png';
import baseInfoIcon from '@/assets/baseInfo-icon.png';
import walletIcon from '@/assets/wallet-icon.png';
import logoutIcon from '@/assets/logout-icon.png';
import store from "@/store";
import {authService, orderService} from "@/services";

export default {
  name: "PersonalInfo",
  data() {
    return {
      store,
      scoreImg,
      baseInfoIcon,
      walletIcon,
      logoutIcon,
      user: {},
      showRechargeAmt: false,
      loanProgress: null,
      rechargeParams: {
        amt: null,
      }
    }
  },
  mounted() {
  },
  activated() {
    this.getUserInfo();
    this.getLoanProgress();
  },
  methods: {
    goPage(path) {
      this.$router.push(path);
    },
    async getLoanProgress() {
      this.loanProgress = await orderService.getLoanProgress();
    },
    async recharge() {
      if (!this.loanProgress || !this.loanProgress.status) {
        this.$toast(this.$t('loan_records_empty'))
        return;
      }
      this.showRechargeAmt = true;
    },
    async confirmRecharge() {
      let link = await orderService.recharge(this.rechargeParams);
      if (link) {
        this.showRechargeAmt = false;
        window.open(link, "_blank");
      }
    },
    async getUserInfo() {
      this.user = await store.getUser();
    },
    logout() {
      authService.logout();
      this.$router.push({name: 'login'});
    },
  }
}
</script>
<style lang="less" scoped>
.my {
  padding-top: 44px;
  padding-bottom: 51px;
  background: url("../../assets/home-background.png") no-repeat;
  background-size: cover;
  min-height: calc(100% - 95px);

  .avatar-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .avatar {
      width: 100px;
      margin-bottom: 10px;
    }

    .score-value, .score-desc {
      color: #33536C;
      font-size: 16px;
      margin-bottom: 5px;
    }

    .score-value {
      font-size: 20px;
    }

    .info-div {
      display: flex;
      flex-direction: row;
      margin-left: 10px;
      color: #FFFFFF;
      flex: 1;
      justify-content: space-between;
    }

    .mobile {
      margin: 15px auto;
      padding: 5px 15px;
      font-size: 14px;
      background-color: #8B8B8B;
      color: #232424;
      border-radius: 15px;
    }

    .credit-score {
      font-size: 16px;
      padding: 5px 20px;
      background: #FFFFFF26;
      border-radius: 15px;
    }

    padding-bottom: 30px;
  }

  .van-cell-group {
    background-color: #ffffff;
  }

  .menu {
    margin-top: 20px;
    .menu-item {
      color: #ffffff;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      margin: 10px 30px;
      border-radius: 30px;
    }
  }

  .gray {
    background-color: #8A929E;
  }

  .green {
    background-color: #54C991;
  }

  .red {
    background-color: #EC6D6D;
  }

  .logout {
    margin-top: 30px;
  }

  .recharge-div {
    .confirm-recharge {
      display: flex;
      justify-content: space-between;
      margin: 20px 20px 10px 20px;

      .cancel, .confirm {
        background-color: rgb(41, 105, 255);
        color: #ffffff;
        border-radius: 5px;
        box-shadow: none;
        border: none;
        font-size: 13px;
        padding: 5px 10px;

        &.disabled {
          opacity: 0.6;
        }
      }
    }
  }
}
</style>
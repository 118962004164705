<template>
  <div class="page">
    <header-bar :title="$t('partner')"/>
    <div class="top-div">
      <img :src="partner2Img"/>
    </div>
    <div class="partner-info">
      <img :src="partnerImg">
    </div>
  </div>
</template>
<script>
import partnerImg from '../assets/partner.png';
import partner2Img from '../assets/partner2.png';

export default {
  name: "PartnerPage",
  data() {
    return {
      partnerImg,
      partner2Img,
    }
  },
}
</script>
<style lang="less" scoped>
.page {
  background: url("../assets/background1.png") no-repeat;
  background-size: cover;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .top-div {
    margin: 60px 0 20px;
    display: flex;
    justify-content: center;

    img {
      width: 20%;
    }
  }

  .partner-info {
    flex-grow: 1;
    background-color: #002464;
    border-radius: 30px 30px 0 0;
    padding: 10px 20px;

    img {
      width: 100%;
    }
  }
}
</style>

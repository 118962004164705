<template>
  <div class="page">
    <header-bar/>
    <div class="order-info" v-if="order && order.status == 3">
      <div class="title">
        <span>{{$t('transaction_history').toUpperCase()}}</span>
      </div>
      <div class="order-item">
        <div class="order-detail">
          <span class="desc">{{ $t('common_order_id') }}:</span>
          <span class="vale">{{ order.orderId }}</span>
        </div>
        <div class="order-detail">
          <span class="desc">{{ $t('common_apply_datetime') }}:</span>
          <span class="vale">{{ order.applyTime }}</span>
        </div>
        <div class="order-detail">
          <span class="desc">{{ $t('withdraw_amount') }}:</span>
          <span class="vale">{{ order.lastWithdrawAmount }}</span>
        </div>
        <div class="order-detail">
          <span class="desc">{{ $t('status') }}:</span>
          <span class="vale" :class="[statusDesc ? 'red': 'green']">{{ statusDesc || 'Pending' }}</span>
        </div>
      </div>
    </div>
    <van-empty description="No data" v-else/>
  </div>
</template>
<script>

import {orderService} from "@/services";

export default {
  name: "TransactionHistory",
  data() {
    return {
      order: {},
      statusDesc: null,
    }
  },
  activated() {
    this.getOrderList();
    this.getLastWithdrawError();
  },
  methods: {
    async getOrderList() {
      let orderList = await orderService.getOrderList();
      this.order = orderList && orderList.length && orderList[0]
    },
    async getLastWithdrawError() {
      this.statusDesc = await orderService.getLastWithdrawError();
    },
  }
}
</script>
<style lang="less" scoped>
.page {
  background-color: #AAAAAA;
  min-height: calc(100% - 44px);
  padding-top: 44px;
  display: flex;
  flex-direction: column;

  .order-info {
    margin-top: 30%;
    background-color: #D9D9D9;
    padding: 10px 5px;
    flex: 1;
    border-radius: 15px 15px 0 0;
    display: flex;
    flex-direction: column;

    .title {
      text-align: center;
      color: @base-color;
      font-size: 22px;
    }

    .order-item {
      margin: 20px 2px 0;
      padding: 15px;
      background-color: #ffffff;
      flex: 1;
    }

    .order-detail {
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      border-bottom: 1px solid #D9D9D9;
      padding-bottom: 6px;

      .desc {
        color: #333333;
      }

      .value {
        color: #333333;
      }

      .red {
        color: #F56C6C !important;
      }
    }
  }

  .green {
    color: green !important;
  }
}
</style>

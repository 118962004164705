<template>
  <div class="page">
    <img class="title" :src="systemImg">
    <div class="lock-div">
      <img class="lock" :src="lockImg"/>
    </div>
    <div class="input-div">
      <span class="tips1">{{$t('lock_tips_1')}}</span>
      <span class="tips2">{{$t('lock_tips_2')}}</span>
      <span class="tips2">{{$t('lock_tips_3')}}</span>
      <el-input class="code-input" type="text" v-model="unlockCode" :placeholder="$t('common_input_placeholder')"/>
      <van-button class="code-btn" :loading="loading" :disabled="!unlockCode" :class="{'disabled': !unlockCode}"
                  size=small @click="unlock">
        OK
      </van-button>
    </div>
  </div>
</template>
<script>
import lockTipsImg from '../assets/lock-tips.png';
import lockImg from '../assets/lock.png';
import systemImg from '../assets/system.png';

import {userService} from "@/services";
import store from '@/store';

export default {
  name: "LockPage",
  data() {
    return {
      lockTipsImg,
      lockImg,
      systemImg,
      unlockCode: '',
      loading: false,
      info: {},
    }
  },
  computed: {
    infoItem() {
      return [
        {
          "label": this.$t("common_name"),
          "value": this.info.realName
        },
        {
          "label": this.$t('common_phone'),
          "value": this.info.mobile
        },
        {
          "label": this.$t("common_id_card"),
          "value": this.info.identity
        },
        {
          "label": this.$t('base_info_detail_address'),
          "value": this.info.address
        },
        {
          "label": this.$t('common_order_id'),
          "value": this.info.lastOrderId
        },
      ]
    },
  },
  async activated() {
    // this.info = await store.getUser(true);
  },
  methods: {
    async unlock() {
      let params = {
        unlockCode: this.unlockCode,
      }
      if (this.unlockCode) {
        this.loading = true;
        userService.unlock(params)
            .then((token) => {
              this.loading = false;
              store.setUnlockToken(token);
              this.$router.push('/');
            })
            .catch(() => this.loading = false);
      }
    },
  }
}
</script>
<style lang="less" scoped>
.page {
  background: url("../assets/lock-background.png") no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;

  .title {
    width: 25%;
    display: block;
    color: #ffffff;
    padding: 20px 20px;
  }

  .lock-div {
    display: flex;
    flex-direction: column;
    padding-top: 10%;
    align-items: center;
    margin-top: 10%;


    .lock {
      width: 20%;
      margin-bottom: 20px;
    }

    .title2 {
      margin: 20px 0;
      font-size: 20px;
      color: #ffffff;
    }
  }

  .info {
    width: 80%;
    padding: 15px 20px 10px;
    background: linear-gradient(180deg, #2752E7 0%, #E43832 100%);

    border-radius: 8px;
    .avatar {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px;

      img {
        width: 60px;
        height: 60px;
        border-radius: 100%;
      }

      span {
        margin-left: 20px;
        color: #ffffff;
        font-size: 18px;
        font-weight: 700;
      }
    }

    .detail {
      font-size: 14px;
      color: #ffffff;

      .detail-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;

        .detail-desc {
        }

        .detail-value {
          max-width: 60%;
          opacity: 0.8;
        }
      }
    }
  }

  .input-div {
    margin: 0 35px;
    display: flex;
    flex-direction: column;
    .tips1 {
      color: #ffffff;
      font-size: 24px;
      text-align: center;
      margin-bottom: 10px;
    }
    .tips2 {
      color: #ffffff;
      font-size: 16px;
      text-align: center;
      margin-bottom: 12px;
      opacity: 0.7;
    }
    .code-input {
      /deep/ input {
        height: 45px;
        border-radius: 8px;
      }

      margin-bottom: 20px;
    }

    .code-btn {
      width: 100%;
      height: 45px;
      border-radius: 8px;
      background: @button-color;
      color: #FFFFFF;
      font-size: 16px;
      border: none;

      &.disabled {
        opacity: 0.6;
      }
    }
  }
}
</style>

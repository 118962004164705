<template>
  <div class="img-div" ref="imageList">
    <template v-for="(item, index) of imgList">
      <img class="img-item" :src="item.img" :key="index"/>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ImageList',
  data() {
    return {
      imgList: [
        {
          img: require("@/assets/middle/1.png"),
        },
        {
          img: require("@/assets/middle/2.png"),
        },
        {
          img: require("@/assets/middle/3.png"),
        },
      ],
    };
  },
  mounted() {

  },
  methods: {},
};
</script>
<style lang="less" scoped>
.img-div {
  display: flex;
  overflow-x: auto;
  justify-content: center;

  .img-item {
    border-radius: 5px;
    border: 1px solid #ffffff;
    width: 45%;
    margin: 0 3px;
  }
}
</style>

<template>
  <div class="bottom">
    <div class="bottom-item" v-for="item of menuList" :class="{'active': currentName===item.name}"
         @click="goto(item)" :key="item.name">
      <div class="inner-div">
        <img :src="item.icon" class="item-icon">
      </div>
    </div>
  </div>
</template>
<script>
import homeImage from '@/assets/home.png';
import settingImg from '@/assets/setting.png';

export default {
  name: "BottomBar",
  props: ["currentName"],
  data() {
    return {
      menuList: [{
        name: 'Home',
        icon: homeImage,
        path: '/'
      }, {
        name: 'My',
        icon: settingImg,
        path: '/my'
      }]
    }
  },
  methods: {
    goto(item) {
      if (item.name === this.currentName){
        return;
      }
      this.$router.push(item.path);
    }
  }
}
</script>
<style lang="less" scoped>
.bottom {
  background: #ffffff;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  height: 45px;
  .bottom-item {
    flex: 1;
    justify-content: space-around;
    display: flex;
    background-color: #0E2A69;
    height: 100%;
    &:first-child {
      border-right: 1px solid #ffffff;
    }
  }
  .inner-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #535D66;
    .item-icon {
      height: 30px;
    }
    .item-name {
      font-size: 12px;
      color: #ffffff;
      opacity: 0.6;
      display: none;
    }
  }
}
</style>
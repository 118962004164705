<template>
  <div class="img-div">
    <template v-for="(item, index) of imgList">
      <img class="img-item" :src="item.img" :key="index"/>
    </template>
  </div>
</template>

<script>
export default {
  name: 'TopImage',
  data() {
    return {
      imgList: [
        {
          img: require("@/assets/top/DOF_logo.png"),
        },
        {
          img: require("@/assets/top/BSP_logo.png"),
        },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.img-div {
  display: flex;
  margin: 0 10px 0;
  position: absolute;

  .img-item {
    width: 15%;
    margin: 0 5px;
  }
}
</style>
